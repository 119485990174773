<template>
  <el-dialog
    title="选择省份/地区"
    width="824px"
    append-to-body
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <div class="whole-warpper" v-loading="loading">
      <div class="left">
        <div class="search-warpper">
          <el-input
            size="mini"
            v-model="province"
            placeholder="请输入省份名称或首字母 (不区分大小写) 进行检索"
            style="width: 320px"
            clearable
          >
          </el-input>
        </div>
        <div class="continent-select-warpper">
          <div v-for="provinceList in finalList" :key="provinceList.label">
            <span class="mr10">{{ provinceList.label }}</span>
            <el-checkbox-group v-model="checkedProvince" @change="onChange">
              <el-checkbox
                v-for="item in provinceList.children"
                :disabled="forbiddenList.includes(item.id)"
                :key="item.id"
                :label="item.id"
                >{{ item.areaCnName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="right">
        <span class="search-warpper"
          >已选省份/地区：<span class="cancel-all" @click="cancelAllButton">(取消全部)</span></span
        >
        <div class="select">
          <el-checkbox-group v-model="checkedProvince" @change="onChange">
            <el-checkbox
              v-for="item in checkedProvinceList"
              :disabled="forbiddenList.includes(item.id)"
              :label="item.id"
              :key="item.areaCnName"
              >{{ item.areaCnName }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <template #footer>
      <loading-btn :isDisabled="!!!checkedProvince.length" type="primary" @click="onConfirm"> 确定 </loading-btn>
      <LoadingBtn @click="onCancel"> 取消 </LoadingBtn>
    </template>
  </el-dialog>
</template>

<script>
import { getProvinceList } from '@/api/template'
import { flat } from '@/utils'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { deepClone } from '@/components/avue/utils/util'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    forbiddenList: {
      type: Array,
      default: () => []
    },
    selectIdList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      province: '',
      loading: false,
      provinceList: [],
      checkedProvince: []
    }
  },
  async mounted() {
    const data = {
      level: 1,
      ...REQUEST_ALL_DATA
    }
    this.loading = true
    const { code, detail } = await getProvinceList(data)
    if (!$SUC(code)) {
      // 为省市打上首字母字段标签
      this.provinceList = detail.map((item) => {
        if (item.areaEnName) {
          item.letters = item.threeCharCode?.substr(0, 1)
        }
        return item
      })
    }
    this.loading = false
  },
  computed: {
    // 格式化后端返回的数据
    formatData({ provinceList }) {
      const obj = {}
      const arr = []
      provinceList.forEach((v, i) => {
        obj[v.letters] = []
      })
      provinceList.forEach((v, i) => {
        obj[v.letters].push(v)
      })
      Object.keys(obj).forEach((v, i) => {
        arr.push({
          label: v,
          children: obj[v]
        })
      })
      arr.sort((a, b) => {
        return a.label.charCodeAt(0) - b.label.charCodeAt(0)
      })
      return arr
    },
    // 由搜索条件-数据驱动视图绑定的返回数据
    finalList() {
      if (this.province.length == 0) return this.formatData
      else {
        this.province = this.province.toUpperCase()
        if (this.province.length == 1 && 65 <= this.province.charCodeAt(0) && 90 >= this.province.charCodeAt(0)) {
          return this.formatData.filter((item) => item.label == this.province)
        } else {
          const res = flat(
            this.formatData.map((item) => {
              return item.children.map((cur) => {
                if (cur.areaCnName == this.province) return item
              })
            })
          ).filter((boolean) => boolean)
          return res.length == 0 ? [] : res
        }
      }
    },
    checkedProvinceList() {
      const checkeIdList = this.checkedProvince.map((item) => item)
      return this.provinceList.filter((item) => {
        if (checkeIdList.includes(item.id)) return item
      })
    }
  },
  watch: {
    dialogVisible(val) {
      const checkedIdList = [...this.selectIdList, ...this.forbiddenList]
      this.checkedProvince = deepClone(checkedIdList)
    }
  },

  methods: {
    onChange() {},
    cancelAllButton() {
      this.checkedProvince = []
    },
    async onConfirm() {
      this.$emit('handleConfirmed', this.checkedProvinceList)
      this.$emit('update:dialogVisible', false)
    },
    onCancel() {
      this.$emit('update:dialogVisible', false)
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 0px;
  }
  .el-dialog__header {
    padding: 20px 20px 10px;
    border-bottom: 1px solid #dcdee0;
  }
}
.whole-warpper {
  display: flex;
  height: 500px;
  .left {
    width: 527px;
    border-right: 1px solid #dcdee0;
    border-bottom: 1px solid #dcdee0;
    .continent-select-warpper {
      padding: 16px 0 0 16px;
      height: 430px;
      overflow: auto;
      ::v-deep {
        .el-checkbox__label {
          margin-right: 10px;
        }
      }
      .all {
        ::v-deep {
          .el-checkbox__label {
            padding-right: 25px;
          }
        }
      }
      ::v-deep {
        .el-checkbox__inner {
          width: 16px;
          height: 16px;
          border: 1px solid #c8c9cc;
          border-radius: 2px;
        }
        .el-checkbox-group {
          display: inline;
        }
        .el-checkbox__label {
          padding-left: 5px;
        }
        .el-checkbox {
          color: #595961;
          font-weight: normal;
          margin: 0px 3px 13px 0px;
        }
      }
    }
    .search-warpper {
      height: 56px;
      line-height: 56px;
      border-bottom: 1px solid #dcdee0;
      padding-left: 16px;
    }
  }
  .right {
    width: 296px;
    border-bottom: 1px solid #dcdee0;
    .cancel-all {
      color: #3841db;
      cursor: pointer;
    }
    .select {
      padding: 16px 0 0 16px;
      height: 420px;
      overflow: auto;
      .el-checkbox {
        color: #595961;
        font-weight: normal;
        margin-bottom: 13px;
      }
    }
    .search-warpper {
      display: inline-block;
      height: 56px;
      width: 100%;
      line-height: 56px;
      border-bottom: 1px solid #dcdee0;
      padding-left: 16px;
      color: #1a1a1a;
    }
  }
}
</style>
